<template>
	<popup-modal ref="popup" class="popup">
		<h2 style="margin-top: 0">{{ title }}</h2>
		<p>{{ message }}</p>
		<div class="btns">
			<span class="ok-btn" @click="_confirm">{{ okButton }}</span>
			<span class="cancel-btn" @click="_cancel">{{ cancelButton }}</span>
		</div>
	</popup-modal>
</template>

<script>
import PopupModal from '../PopupModal.vue'

export default {
	name: 'ConfirmDialogue',
	components: { PopupModal },
	data() {
		return {
			// Parameters that change depending on the type of dialogue
			title: undefined,
			message: undefined, // Main text content
			okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
			cancelButton: 'Cancel', // text for cancel button
			
			// Private variables
			resolvePromise: undefined,
			rejectPromise: undefined,
		}
	},
	methods: {
		show(opts) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
		_confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },
        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
	}
}
</script>

<style>
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ok-btn {
	padding: 0.5em 1em;
    color: red;
    cursor: pointer;
}
.cancel-btn {
    padding: 0.5em 1em;
    cursor: pointer;
}
</style>